import { useAppDispatch, useAppSelector } from '../application/configureStore';
import {
  dataFilteringSliceActions,
  FilterSetup,
  FilterState,
  getDataFiltersForTable,
} from './dataFiltering.slice';
import { useEffect } from 'react';

const { setSearchText, setSingleFilter, setMultiFilter, initializeFilter } =
  dataFilteringSliceActions;

export const useDataFiltering = <ColumnField extends string>({
  tableId,
  ...providedFilters
}: FilterSetup<ColumnField>) => {
  const dispatch = useAppDispatch();

  const filters: FilterState<string> = useAppSelector(getDataFiltersForTable(tableId));

  useEffect(() => {
    // This hook might be called just to "fetch" the info about the filters, in which
    // case filters aren't being provided and we don't need to initialize anything.
    if (!filters && Object.entries(providedFilters).length) {
      dispatch(initializeFilter({ tableId, ...providedFilters }));
    }
  }, []);

  // `filters` will start undefined so in order to be able
  // to destructure we'll fall back to an empty object.
  const { searchKey, searchText, singleFilters, multiFilters } = filters ?? {};

  return {
    searchText,
    searchKey,
    singleFilters: Object.values(singleFilters || {}),
    multiFilters: Object.values(multiFilters || {}),
    setSearchText: (searchText: string) => dispatch(setSearchText({ tableId, searchText })),
    setSingleFilter: ({ field, value }: Omit<Parameters<typeof setSingleFilter>[0], 'tableId'>) =>
      dispatch(setSingleFilter({ tableId, field, value })),
    setMultiFilter: ({ field, values }: Omit<Parameters<typeof setMultiFilter>[0], 'tableId'>) =>
      dispatch(setMultiFilter({ tableId, field, values })),
  };
};
