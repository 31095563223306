import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';
import PropsListPage from '../PropsListPage/PropsListPage';
import { TabsRouter } from '../../components/TabsRouter';
import PropsPlayerList from '../PropsPlayerList';
import PropsEventSchedule from '../../components/PropsEventSchedule';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

const PlayerPropsPage = () => (
  <S.Wrapper data-testid='PlayerPropsPage'>
    <Typography variant='h1'>Player Props</Typography>

    <TabsRouter
      tabs={[
        { title: 'Props', path: 'player-props', element: <PropsListPage />, index: true },
        { title: 'Player List', path: 'player-list', element: <PropsPlayerList/> },
        { title: 'Event Schedule', path: 'event-schedule', element: <PropsEventSchedule/> },
      ]}
    />

    <Outlet />
  </S.Wrapper>
);

export default PlayerPropsPage;
