import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FilterOption, OptionValue } from '../../types/dataPresentation.types';

const S = {
  Dropdown: styled(Select)`
    height: 40px;
    width: 220px;
    background: white;
  `,
};

export type SingleFilterAdornment = {
  allString: string;
};

export type SingleFilterConfig = {
  options: FilterOption[];
} & SingleFilterAdornment;

type Props = SingleFilterConfig & {
  onChange?: (val: OptionValue) => void;
  value?: OptionValue;
};

export const FilterSelect: React.FC<Props> = ({ allString, options, onChange, value }) => {
  const [selectedValue, setSelectedValue] = useState('all');

  // the typings here are may appear (and be) a little confusing
  // but they are indeed the correct way to satisfy typescript.
  // see [here](https://stackoverflow.com/a/69746469/6826164).
  const onSelect = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value as string;
    setSelectedValue(val);
    onChange?.call({}, val);
  };
  return (
    <S.Dropdown value={value || selectedValue} data-testid='filterSelect' onChange={onSelect}>
      <MenuItem value={'all'}>{allString}</MenuItem>

      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </S.Dropdown>
  );
};
export default FilterSelect;
