import React, { useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { globalStyles, theme } from './theme';
import { Provider } from 'react-redux';
import { createStore, RootState } from './configureStore';
import { LicenseInfo } from '@mui/x-license-pro';

// set license for MUI-X Pro, before any components are rendered.
// even though AppWrapper isn't the root of the app, this is executed
// when this file is loaded (imported) at all.
// the key is "designed to be public" (see https://mui.com/x/advanced-components/#security)
LicenseInfo.setLicenseKey(
  'd2d9ef647492dc8d941b2f0dd9e0de80Tz00NTM2OCxFPTE2ODYzMzkxNzM3MzEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

type Props = {
  store?: ReturnType<typeof createStore>;
  initialState?: RootState;
};

type WindowWithStore = Window & typeof globalThis & { store: ReturnType<typeof createStore> };

export const AppWrapper: React.FC<Props> = ({ children, store, initialState }) => {
  console.clear();
  
  const appStore = store || createStore(initialState);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const win = window as WindowWithStore;
      win.store = appStore;
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        {globalStyles}
        <Provider store={appStore}>{children}</Provider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
};
