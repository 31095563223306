import { Gamepad, Settings, SupervisorAccount } from '@mui/icons-material';
import { ReactElement } from 'react';
import PlayerPropsPage from '../pages/PlayerPropsPage';
import UserProfilePage from '../pages/UserProfilePage/';
import FeatureManagementPage from '../pages/FeatureManagementPage';

export type NavConfig = {
  title: string;
  path: string;
  nested?: boolean;
  icon?: ReactElement;
  config?: NavConfig;
  element?: ReactElement;
}[];

export const config: NavConfig = [
  {
    title: 'Prop Contests',
    icon: <Gamepad />,
    path: 'prop-contests',
    config: [
      { title: 'Player Props', path: 'player-props', element: <PlayerPropsPage />, nested: true },
      { title: 'Contests & Pools', path: 'contests-pools' },
    ],
  },
  {
    title: 'Users',
    icon: <SupervisorAccount />,
    path: 'users',
    config: [{ title: 'Profile', path: 'profile', element: <UserProfilePage /> }],
  },
  {
    title: 'Settings',
    icon: <Settings />,
    path: 'settings',
    config: [
      {
        title: 'Feature Management',
        path: 'feature-management',
        element: <FeatureManagementPage />,
      },
    ],
  },
];
