import { useGetSportsQuery } from '../../services/superdraft-api/sports.endpoints';
import { useGetPropsDashboardQuery } from '../../services/superdraft-api/playerProps.endpoints';
import { skipToken } from '@reduxjs/toolkit/query';

export const usePlayerPropsList = () => {
  const { data: sports } = useGetSportsQuery();
  const {
    data: dashboard,
    isLoading,
    isError,
    error
  } = useGetPropsDashboardQuery(sports || skipToken);
  
  return {
    playerProps: dashboard?.activeGridRecords,
    dashboard,
    isLoading,
    isError,
    error
  };
};
