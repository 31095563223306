import adminApi from './adminApi';
import ApiConstants from './apiConstants';

export type SportsApiPayload = {
  sportId: number;
  sportName: string;
};

export const {
  useGetSportsQuery,
  endpoints: { getSports },
} = adminApi.injectEndpoints({
  endpoints: build => ({
    getSports: build.query<Record<number, SportsApiPayload>, void>({
      query: ApiConstants.sports.getSports.queryFn,
      transformResponse: (sports: SportsApiPayload[]) =>
        sports.reduce(
          (obj, sport) => ({
            ...obj,
            [sport.sportId]: sport,
          }),
          {},
        ),
    }),
  }),
});
