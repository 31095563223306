import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import adminApi from './adminApi';
import { NewUserSessionRequest, NewUserSessionResponse } from '@superdraft/super-draft-common';
import ApiConstants from './apiConstants';

type UserPayload = CognitoUserAmplify & {
  signInUserSession: { idToken: string };
  attributes: {
    birthdate: string;
    email: string;
    preferred_username: string;
  };
  pool: { clientId: string | number }
};

export const { useRequestSessionMutation } = adminApi.injectEndpoints({
  endpoints: build => ({
    requestSession: build.mutation<NewUserSessionResponse, CognitoUserAmplify>({
      query: response => {
        const {
          signInUserSession: { idToken: accessToken },
          attributes: { birthdate, email, preferred_username: userName },
        } = response as UserPayload;

        const body: NewUserSessionRequest = {
          accessToken,
          type: 'external',
          deviceType: 0, // 0 = Web
          dob: new Date(birthdate),
          email,
          userName,
        };
        
        return {
          url: ApiConstants.user.postSession.queryFn(),
          method: ApiConstants.user.postSession.method,
          body,
        };
      },
    }),
  }),
});
