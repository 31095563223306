import { PlayerPropJson } from '../../json-builder-models/PlayerProp';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const S = {
  Users: styled.div`
    display: flex;
    gap: 1ch;
  `,
};

export const OverUnderUsers = ({
  playerProp: { overUsers, underUsers },
}: {
  playerProp: PlayerPropJson;
}) => {
  const total = overUsers + underUsers;
  return (
    <S.Users>
      <Typography fontSize={14}>{total}</Typography>
      <Typography variant='body2'>
        ({overUsers} O, {underUsers} U)
      </Typography>
    </S.Users>
  );
};
