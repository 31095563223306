import { useGetSportsQuery } from '../../services/superdraft-api/sports.endpoints';
import { useGetPropsPlayersQuery } from '../../services/superdraft-api/playerProps.endpoints';
import { skipToken } from '@reduxjs/toolkit/query';

export const usePropsPlayersList = () => {
  const { data: sports } = useGetSportsQuery();
  const { data: players, isLoading, isError, error } = useGetPropsPlayersQuery(sports || skipToken);

  return {
    players,
    isLoading,
    isError,
    error,
  };
};
