import { userSliceActions } from '../slices/user.slice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../application/configureStore';
import { SignOutFn } from './AppAuthenticator/AppAuthenticator';

const useLogout = ({ signOut: amplifySignout }: { signOut: SignOutFn }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    amplifySignout();
    dispatch(userSliceActions.signOut());
    navigate('/');
  };
};

export default useLogout;
