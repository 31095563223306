import adminApi from './adminApi';
import ApiConstants from './apiConstants';
import { PropAdminDashBoard, PropAdminSportActivity } from '@superdraft/superdraft-dfs-data-types';
import { PlayerProp, PlayerPropJson } from '../../json-builder-models/PlayerProp';
import { PropAdminActiveGridRecord } from '@superdraft/superdraft-dfs-data-types/lib/dashBoards/props';
import {
  PropAdminPlayerGridRecord,
  PropAdminPlayersDashboard,
} from '../../types/until-npm-update/dashBoards/propsPlayers';
import {
  ArrayWithSportName,
  getNameForSport,
  getNameForSportId,
  ObjectWithSportName,
  SportsMap,
} from './sportName.helpers';

export type PropAdminUIDashBoard = Omit<
  PropAdminDashBoard,
  'activeGridRecords' | 'activeSummaryBySport'
> & {
  activeGridRecords: PlayerPropJson[];
  activeSummaryBySport: ObjectWithSportName<PropAdminSportActivity>;
};

export const {
  useGetPropsDashboardQuery, useGetPropsPlayersQuery,
  endpoints: { getPropsDashboard },
} = adminApi.injectEndpoints({
  endpoints: build => ({
    getPropsDashboard: build.query<PropAdminUIDashBoard, SportsMap>({
      // note: this must be an arrow fn, can't just use `query: queryFn`
      query: () => ApiConstants.playerProps.getPropsDashboard.queryFn(),
      transformResponse: (dashboard: PropAdminDashBoard, _meta, sports) => {
        const isFantasyProp = (p: PropAdminActiveGridRecord) => !!Object.entries(p.line).length;

        // add sportName to props
        const playerProps: PlayerPropJson[] = dashboard.activeGridRecords
          .filter(isFantasyProp)
          .map(json => ({
            ...PlayerProp.build(json),
            sportName: getNameForSport(json, sports),
          }));

        // add sportName to summaries
        const sportSummaries = Object.values(dashboard.activeSummaryBySport).reduce(
          (obj, sport) => ({
            ...obj,
            [sport.sportId]: { ...sport, sportName: getNameForSport(sport, sports) },
          }),
          {},
        );

        return {
          ...dashboard,
          activeGridRecords: playerProps,
          activeSummaryBySport: sportSummaries,
        };
      },
    }),
    getPropsPlayers: build.query<ArrayWithSportName<PropAdminPlayerGridRecord>, SportsMap>({
      query: ApiConstants.playerProps.getPropsPlayers.queryFn as () => string,
      transformResponse: (players: PropAdminPlayersDashboard, _meta, sports) => {
        return players.map(p => ({ ...p, sportName: getNameForSportId(p.sportId, sports) }));
      },
    }),
  }),
});
