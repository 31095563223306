import React from 'react';
import styled from 'styled-components';
import SummaryFrame from '../SummaryFrame';
import { GridDollarFormatter } from '../../utils/gridFormatters';
import PropsCashBySport from '../PropsCashBySport';
import { PropAdminUIDashBoard } from '../../services/superdraft-api/playerProps.endpoints';

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    height: 128px;
  `,
};

const PlayerPropsInfoDashboard = ({ dashboard }: { dashboard: PropAdminUIDashBoard }) => {
  const actionCash = GridDollarFormatter({ value: dashboard.openAction });

  return (
    <S.Wrapper data-testid='PlayerPropsInfoDashboard'>
      <SummaryFrame title='Open Action' value={actionCash} />
      <SummaryFrame title='Open Props' value={dashboard.openProps} />

      <PropsCashBySport summary={dashboard.activeSummaryBySport} />
    </S.Wrapper>
  );
};

export default PlayerPropsInfoDashboard;
