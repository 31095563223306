import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { ReactElement } from 'react';
import Tab from '@mui/material/Tab';

export const S = {
  Tabs: styled(Tabs)`
    .MuiTabs-indicator {
      height: 4px;
      border-radius: 8px 8px 0 0;
    }
  `,
  Link: styled(Link)`
    && {
      color: black;
      text-transform: capitalize;
      font-weight: 500;
      width: 140px;
    }
  `,
};

export type TabsConfig = {
  title: string;
  path: string;
  index?: boolean;
  element: ReactElement;
};

export const TabsRouter = ({ tabs }: { tabs: TabsConfig[] }) => {
  const location = useLocation();
  const indexPath = tabs.find(t => t.index);
  const currentValue = location.pathname.split('/').pop() || indexPath?.path;

  return (
    <>
      <S.Tabs value={currentValue}>
        {tabs.map(({ title, path, index }) => (
          <Tab key={path} label={title} to={index ? '' : path} value={path} component={S.Link} />
        ))}
      </S.Tabs>

      <Routes>
        {tabs.map(({ path, index, element }) => (
          <Route key={path} path={index ? '' : path} index={index} element={element} />
        ))}
      </Routes>
    </>
  );
};
