import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { dataFilters } from '../slices/dataFiltering.slice';
import { user } from '../slices/user.slice';
import adminApi from '../services/superdraft-api/adminApi';
import { setupListeners } from '@reduxjs/toolkit/query';

// defining this outside of the call to configureStore gives the store type-awareness
export const reducer = combineReducers({
  dataFilters,
  user,
  [adminApi.reducerPath]: adminApi.reducer,
});

export const createStore = (initialState?: ReturnType<typeof reducer>) => {
  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([adminApi.middleware]),
    preloadedState: initialState,
  });
  // required for refetchOnFocus/refetchOnReconnect behaviors
  setupListeners(store.dispatch);
  return store;
};

type StoreType = ReturnType<typeof createStore>;
export type RootState = ReturnType<StoreType['getState']>;
export type AppDispatch = StoreType['dispatch'];

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
