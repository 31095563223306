import React from 'react';
import styled from 'styled-components';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';

const S = {
  Wrapper: styled(Card)`
    && {
      width: fit-content;
      border-radius: 8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  `,
  Content: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Title: styled(Typography).attrs({ variant: 'body2' })`
    font-size: 14px;
  `,
  Value: styled(Typography)`
    && {
      font-size: 32px;
      font-weight: 700;
    }
  `,
};

type Props = { title?: string; value?: string | number };

const SummaryFrame: React.FC<Props> = ({ title, value, children, ...props }) => (
  <S.Wrapper data-testid='SummaryFrame' { ...props }>
    <S.Content>
      <S.Title>{ title }</S.Title>
      <S.Value>{ value }</S.Value>
      { children }
    </S.Content>
  </S.Wrapper>
);

export default SummaryFrame;
