import { getUserDisplayName } from '../slices/user.slice';
import styled from 'styled-components';
import { SignOutFn } from '../auth/AppAuthenticator/AppAuthenticator';
import { useAppSelector } from '../application/configureStore';

const S = {
  Link: styled.a`
    margin-top: 32px;
    padding: 8px;
    cursor: pointer;
    align-self: center;
  `,
};

export const SignOutButton = ({ signOut }: { signOut: SignOutFn }) => {
  const username = useAppSelector(getUserDisplayName);
  return <S.Link onClick={signOut}>Sign out of {username}</S.Link>;
};
