import { GridDollarFormatter } from '../../utils/gridFormatters';
import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { ChartData } from './PropsCashBySport';

const S = {
  Wrapper: styled.li`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Bullet: styled.div`
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: ${p => p.color};
  `,
  Row: styled.div`
    display: flex;
  `,
  MainInfo: styled(Typography)`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
  `,
  Space: styled.div`
    width: 0.5ch;
  `,
  SecondaryInfo: styled(Typography).attrs({ variant: 'body2' })`
    font-size: 15px;
    display: flex;
    align-items: center;
  `,
};

type Props = ChartData & { percent: number };

export const SportCashSummary = ({ color, title, value, percent }: Props) => (
  <S.Wrapper key={title}>
    <S.Bullet color={color} />
    <S.Row>
      <S.MainInfo>
        {title}: {GridDollarFormatter({ value }, 0)}
      </S.MainInfo>
      <S.Space />
      <S.SecondaryInfo>({percent}%)</S.SecondaryInfo>
    </S.Row>
  </S.Wrapper>
);
