import { NavGroup } from './NavGroup';
import styled from 'styled-components';
import { Drawer } from '@mui/material';
import { config } from '../routingConfig';
import SDLogo from '../../../assets/images/SuperDraft_horizontal_primary_black.svg';
import { SignOutButton } from '../SignOutButton';
import { SignOutFn } from '../../auth/AppAuthenticator/AppAuthenticator';

const S = {
  Wrapper: styled(Drawer).attrs({ variant: 'permanent', anchor: 'left' })`
    .MuiDrawer-paper {
      width: 256px;
      height: 100%;
    }

    // note: this is a quick fix to hide the nav in development when resizing.
    //  there is no "hamburger" or other way to show the nav if you're
    //  actually on a small screen!
    @media (max-width: 1000px) {
      display: none;
    }
  `,
  LogoWrapper: styled.div`
    height: 72px;
    padding: 32px 0 16px 16px;
    box-sizing: border-box;
  `,
  Logo: styled.img.attrs({ src: SDLogo })`
    width: 150px;
  `,
};

const SideNavigation = ({ signOut }: { signOut: SignOutFn }) => {
  return (
    <S.Wrapper data-testid='sideNavigation'>
      <S.LogoWrapper>
        <S.Logo />
      </S.LogoWrapper>
      {config.map(conf => (
        <NavGroup {...conf} key={conf.path} />
      ))}
      <SignOutButton signOut={signOut} />
    </S.Wrapper>
  );
};

export default SideNavigation;
