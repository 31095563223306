import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../application/configureStore';
import { OptionValue } from '../types/dataPresentation.types';
import { SingleFilterAdornment } from '../components/FilterSelect/FilterSelect';
import { MultiFilterAdornment } from '../components/FilterButtonGroup/FilterButtonGroup';

type KeyedAction<T> = PayloadAction<{ tableId: string } & T>;

type SingleFilterSetting = {
  value: OptionValue;
  field: string;
};
type MultiFilterSetting = {
  values: OptionValue[];
  field: string;
};

export type FilterSetup<ColumnField extends string> = {
  tableId: string;
  searchKey?: ColumnField;
  singleFilters?: { field: ColumnField; allString: string }[];
  multiFilters?: { field: ColumnField; title: string }[];
};

export type SingleFilterState = SingleFilterAdornment & SingleFilterSetting;
export type MultiFilterState = MultiFilterAdornment & MultiFilterSetting;

export type FilterState<FieldType extends string> = {
  searchKey?: FieldType;
  searchText: string;
  singleFilters: Record<FieldType, SingleFilterState>;
  multiFilters: Record<FieldType, MultiFilterState>;
};

// we can't give a type to FilterState here because
// each FilterState needs to use a different RowType!
const initialState: Record<string, FilterState<string>> = {};

const getInitializedFilters = <ColumnField extends string>({
  searchKey,
  singleFilters,
  multiFilters,
}: Omit<FilterSetup<ColumnField>, 'tableId'>) => ({
  searchKey,
  searchText: '',
  singleFilters: (singleFilters?.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.field]: {
        value: '',
        ...filter,
      },
    }),
    {},
  ) || {}) as FilterState<ColumnField>['singleFilters'],
  multiFilters: (multiFilters?.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.field]: {
        values: [],
        ...filter,
      },
    }),
    {},
  ) || {}) as FilterState<ColumnField>['multiFilters'],
});

const dataFilteringSlice = createSlice({
  name: 'dataFilters',
  initialState,
  reducers: {
    initializeFilter: (state, action: PayloadAction<FilterSetup<string>>) => {
      const { tableId, singleFilters, multiFilters, searchKey } = action.payload;
      if (!tableId) {
        throw new Error(
          'No tableId prop provided for DataPresentation component. ' +
            'This error should be easier to avoid once we use type annotations in our Stories.',
        );
      }

      state[tableId] = getInitializedFilters({
        searchKey,
        singleFilters,
        multiFilters,
      });
    },
    setSearchText: (state, action: KeyedAction<{ searchText: string }>) => {
      const { tableId, searchText } = action.payload;
      state[tableId].searchText = searchText;
    },
    setSingleFilter: (state, action: KeyedAction<SingleFilterSetting>) => {
      const { tableId, field, value } = action.payload;
      state[tableId].singleFilters[field].value = value;
    },
    setMultiFilter: (state, action: KeyedAction<MultiFilterSetting>) => {
      const { tableId, field, values } = action.payload;
      state[tableId].multiFilters[field].values = values;
    },
  },
});

export const { ...dataFilteringSliceActions } = dataFilteringSlice.actions;
export const dataFilters = dataFilteringSlice.reducer;

export const getDataFilters = (state: RootState) => state.dataFilters;
export const getDataFiltersForTable = (tableId: string) => (state: RootState) =>
  state.dataFilters[tableId];
