import { usePlayerPropsList } from '../PlayerPropsPage/usePlayerPropsList';
import Typography from '@mui/material/Typography';
import PlayerPropsInfoDashboard from '../../components/PlayerPropsInfoDashboard';
import DataPresentation from '../../components/DataPresentation';
import { playerPropsColumns } from './playerPropsColumns';
import { PlayerPropJson } from '../../json-builder-models/PlayerProp';
import styled from 'styled-components';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
};

const PropsListPage = () => {
  const { playerProps, isLoading, isError, error, dashboard } = usePlayerPropsList();

  return (
    <S.Wrapper data-testid='PropsListPage'>
      {isLoading && <Typography>Loading...</Typography>}
      {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}
      {playerProps && dashboard && (
        <>
          {!!playerProps.length && <PlayerPropsInfoDashboard dashboard={dashboard} />}
          <DataPresentation
            tableId='playerProps'
            columns={playerPropsColumns}
            rows={playerProps}
            getRowId={(r: PlayerPropJson) => r.propId}
            buttonGroupFilters={[{ field: 'status', title: 'Filter by status' }]}
            searchKey='playerName'
            dropdownFilters={[
              {
                field: 'sportName',
                allString: 'All Sports',
              },
            ]}
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default PropsListPage;
