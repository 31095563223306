import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../application/configureStore';
import { NewUserSessionResponse } from '@superdraft/super-draft-common';

const initialState = {
  user: null as NewUserSessionResponse['user'] | null,
  amplifyToken: null as string | undefined | null,
  sessionToken: null as string | undefined | null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfoOnLogin: (state, action: PayloadAction<Partial<typeof initialState>>) => {
      return { ...state, ...action.payload };
    },
    signOut: () => initialState,
  },
});

export const { ...userSliceActions } = userSlice.actions;
export const user = userSlice.reducer;

export const getUserState = (state: RootState) => state.user;
export const getUserDisplayName = (state: RootState) => getUserState(state).user?.profile.userName;
export const getUserToken = (state: RootState) => getUserState(state).sessionToken;
export const getUserId = (state: RootState) => getUserState(state).user?.userId;
export const getUserEmail = (state: RootState) => getUserState(state).user?.profile.email;
