import React from 'react';
import styled from 'styled-components';
import { Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMultiSelect from '../../utils/hooks/useMultiSelect';
import { FilterOption, OptionValue } from '../../types/dataPresentation.types';

const S = {
  Wrapper: styled(Typography).attrs({ component: 'ul' })`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    gap: 8px;
    color: ${p => p.theme.palette.text.disabled};
  `,
  ChipWrapper: styled.li`
    text-transform: capitalize;
    list-style-type: none;
  `,
};
export type MultiFilterAdornment = {
  // title to display to the left of the buttons
  title: string;
};
export type MultiFilterConfig = {
  // the available options, displayed as buttons (`Chip`s)
  options: FilterOption[];
} & MultiFilterAdornment;

type Props = MultiFilterConfig & {
  // in a controlled component, the currently selected options (by their `value` property)
  values?: OptionValue[];
  // in a controlled component, the handler function to be
  // called when any of the options or selected or deselected
  onChange?: (val: OptionValue[]) => void;
};

const FilterButtonGroup: React.FC<Props> = ({
  title = '',
  options = [],
  onChange,
  values = [],
}) => {
  const { currentValues, onClickOption } = useMultiSelect(values, onChange);

  return (
    <S.Wrapper data-testid='filterButtonGroup'>
      <Typography variant='body2' fontSize={16}>
        {title}
      </Typography>

      {options.map(({ label, value }) => {
        const selected = currentValues.includes(value);

        return (
          <S.ChipWrapper key={value}>
            <Chip
              label={label}
              onClick={() => onClickOption(value)}
              variant={selected ? 'filled' : 'outlined'}
            />
          </S.ChipWrapper>
        );
      })}
    </S.Wrapper>
  );
};
export default FilterButtonGroup;
