import { useAppSelector } from '../../application/configureStore';
import { getUserEmail } from '../../slices/user.slice';
import {
  EnvironmentFeature,
  FeatureCollection,
  useSetFeatureMutation,
} from '../../services/superdraft-api/features.endpoints';
import { Environment } from '../../services/superdraft-api/api-payload-types/features.types';
import { newEnvFeature } from '../FeatureDetail/FeatureDetail';
import { useEffect } from 'react';
import { SetState } from '../../utils/types/utility.types';

export const useCreateFeature = ({
  name,
  close,
  selectFeature,
}: {
  name: string;
  close: () => void;
  selectFeature: SetState<FeatureCollection | null>;
}) => {
  const userEmail = useAppSelector(getUserEmail);
  const [postFeature, { error, isLoading, isSuccess, data: newFeature }] = useSetFeatureMutation();

  const feature: EnvironmentFeature = {
    ...newEnvFeature(Environment.test),
    ownerEmail: userEmail as string,
  };

  useEffect(() => {
    if (isSuccess && newFeature) {
      close();
      selectFeature(newFeature);
    }
  }, [isSuccess]);

  return {
    createFeature: () => postFeature({ name, feature }),
    isLoading,
    isSuccess,
    error,
  };
};
