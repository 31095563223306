import { useMemo } from 'react';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PropAdminUIDashBoard } from '../../services/superdraft-api/playerProps.endpoints';
import { SportCashSummary } from './SportCashSummary';

// these functions generate a color for the sport based (by some mathematical algorithm) on the sport name.
// (this is mainly to avoid having to hardcode sport "colors", but that may be a better end experience.)
const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const pickColor = (str: string) => `hsl(${hashCode(str) % 360}, 80%, 50%)`;

export type ChartData = {
  color: string;
  title: string;
  value: number;
};

const getChartData = (summary: PropAdminUIDashBoard['activeSummaryBySport']) => (): ChartData[] =>
  Object.values(summary).map((sport, i) => ({
    title: sport.sportName,
    value: sport.attributedAmount,
    color: pickColor(`${i * 4}${sport.sportName}`),
  }));

const S = {
  Wrapper: styled(Card)`
    display: flex;
    padding: 24px;
    border-radius: 8px;
    text-transform: uppercase;
    flex: 1;
    align-items: center;
    height: 100%;
    gap: 24px;

    svg {
      height: 80px;
      width: 80px;
    }
  `,
  TextSection: styled.div`
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Summary: styled.ul`
    display: flex;
    margin: unset;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style-type: none;
  `,
};

type Props = {
  summary: PropAdminUIDashBoard['activeSummaryBySport'];
};

const PropsCashBySport = ({ summary }: Props) => {
  const data = useMemo(getChartData(summary), [summary]);
  const total = useMemo(
    () => Object.values(summary).reduce((a, s) => a + s.attributedAmount, 0),
    [summary],
  );

  return (
    <S.Wrapper data-testid='PropsCashBySport'>
      <PieChart data={data} />

      <S.TextSection>
        <Typography variant={'body2'}>Active Total Cash Summary</Typography>

        <S.Summary>
          {data.map(sport => (
            <SportCashSummary
              {...sport}
              key={sport.title}
              percent={Math.floor((sport.value / total) * 100)}
            />
          ))}
        </S.Summary>
      </S.TextSection>
    </S.Wrapper>
  );
};

export default PropsCashBySport;
