import { createTheme, GlobalStyles } from '@mui/material';
import { CSSProperties } from 'react';

export const globalStyles = (
  <GlobalStyles
    styles={{
      ul: { paddingInlineStart: 0 }, // reset mui style
    }}
  />
);

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(20, 147, 255)',
      '100': 'rgba(20, 147, 255, 0.1)',
    },
    background: {
      default: '#FBFCFD',
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '36px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '32px',
      fontWeight: 500,
    },
    body1: {
      color: 'rgba(0, 0, 0, 1.0)',
      lineHeight: '22.5px',
    },
    body2: {
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '22.5px',
    },
    error: {
      color: 'red',
    },
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    error: CSSProperties;
  }

  interface TypographyVariantsOptions {
    error?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}
