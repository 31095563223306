import React from 'react';
import styled from 'styled-components';
import SideNavigation from '../navigation/SideNavigation';
import { AppRouter } from '../navigation/AppRouter';
import { SignOutFn } from '../auth/AppAuthenticator/AppAuthenticator';
import useLogout from '../auth/useLogout';

const S = {
  Wrapper: styled.div`
    display: flex;
    height: 100%;

    .MuiDrawer-root > .MuiPaper-root {
      position: unset;
    }
  `,
  OutletWrapper: styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${p => p.theme.palette.background.default};
  `,
};

type Props = {
  signOut: SignOutFn;
};

const Application: React.FC<Props> = ({ signOut }) => {
  const doSignOut = useLogout({ signOut });

  return (
    <S.Wrapper>
      <SideNavigation signOut={doSignOut} />
      <S.OutletWrapper>
        <AppRouter />
      </S.OutletWrapper>
    </S.Wrapper>
  );
};

export default Application;
