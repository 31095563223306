import { useAppDispatch, useAppSelector } from '../application/configureStore';
import { useEffect } from 'react';
import { getUserState, userSliceActions } from '../slices/user.slice';
import '@aws-amplify/ui-react/styles.css';
import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import { useRequestSessionMutation } from '../services/superdraft-api/auth.endpoints';
import { coreUrl } from '../application/environmentConfig';

const { setUserInfoOnLogin } = userSliceActions;

type PoolUser = CognitoUserAmplify & { pool: { clientId: string | number } };

export const useLogin = (amplifyUser: CognitoUserAmplify) => {
  const dispatch = useAppDispatch();
  const adminUser = useAppSelector(getUserState).user;
  const [doSignIn, { data, error, ...result }] = useRequestSessionMutation();

  const { username, pool } = amplifyUser as PoolUser;

  useEffect(() => {
    // Get the AWS Amplify token, which is written to local storage on login, and save it in redux
    const storageKey = `CognitoIdentityServiceProvider.${pool.clientId}.${username}`;
    const amplifyToken = localStorage.getItem(`${storageKey}.idToken`);
    dispatch(setUserInfoOnLogin({ amplifyToken }));
    
    // after storing the token, sign into the admin api
    doSignIn(amplifyUser);
  }, [pool.clientId]);

  useEffect(() => {
    if (data) {
      // once we have our response, store the full user info in redux
      const { user, session } = data;
      dispatch(setUserInfoOnLogin({ sessionToken: session.sessionToken, user }));
    } else if (error) {
      const message = [
        'Cognito authentication succeeded, but backend authentication failed.',
        "You're likely running against a backend environment that's broken in one way or another.",
        `Your current backend url is ${coreUrl}`,
      ].join('\n');
      window.alert(message);
    }
  }, [result.status]);

  return adminUser;
};
