import styled from 'styled-components';

const S = {
  Wrapper: styled.div``
};

const PropsEventSchedule = () => {
  return <S.Wrapper data-testid='PropsEventSchedule'>
    PropsEventSchedule
  </S.Wrapper>;
};

export default PropsEventSchedule;
