import styled from 'styled-components';
import { usePropsPlayersList } from '../PropsListPage/usePropsPlayersList';
import Typography from '@mui/material/Typography';
import DataPresentation from '../../components/DataPresentation';
import { PropAdminPlayerGridRecord } from '../../types/until-npm-update/dashBoards/propsPlayers';
import { propsPlayersColumns } from './propsPlayersColumns';

const S = {
  Wrapper: styled.div``,
};

const PropsPlayerList = () => {
  const { players, isLoading, isError, error } = usePropsPlayersList();
  return (
    <S.Wrapper data-testid='PropsPlayerList'>
      {isLoading && <Typography>Loading...</Typography>}
      {isError && <Typography> Error: ${JSON.stringify(error)}</Typography>}
      {players && (
        <>
          <DataPresentation
            tableId='playerProps'
            columns={propsPlayersColumns}
            rows={players}
            getRowId={(r: PropAdminPlayerGridRecord) => r.playerId}
            buttonGroupFilters={[{ field: 'sportName', title: 'Filter by ' }]}
            searchKey='playerName'
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default PropsPlayerList;
